import { Injectable } from '@angular/core';
import { NgSellyBaseConfig } from './ng-selly-base.config';

@Injectable()
export class NgSellyConfig extends NgSellyBaseConfig  {

  constructor() {
    super();
    this.apiServer = `https://ng-selly-server-pprod.helvetia.it/`;
    this.operatorLoginUrl = `auth/4Operator`;
    this.liquidatoreLoginUrl = `auth/4Operator`;
    this.apiRegister = 'https://api.selly-preprod.helvetia.it/api/v2/proxy/lambda-api/agreementChangeStatus/',
    this.lambdaApi = 'https://api.selly-preprod.helvetia.it/api/v2/proxy/lambda-api';    
    this.apiKey = 'W0Pp5ItBpWEm6seMh7wsWzB5yfmDpq7s'
  }
}
